<template>
  <div>
    <el-form
      :rules="rules"
      ref="form"
      :model="form"
      label-width="200px"
    >
      <el-form-item
        label="店铺名称："
        prop="name"
      >
        <el-input
          style="width:225px;"
          placeholder="请输入"
          v-model="form.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="店铺简介：">
        <el-input
          style="width:225px;"
          placeholder="请输入"
          v-model="form.intro"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="店铺Logo："
        prop="logo"
      >
        <iyy-upload
          :file-type="1"
          :isRadio="true"
          :file-list.sync="form.logo"
          :limit="1"
        ></iyy-upload>
        <p>注：图片需小于1M</p>
      </el-form-item>
      <el-form-item
        label="负责人姓名："
        prop="contact"
      >
        <el-input
          style="width:225px;"
          placeholder="请输入"
          v-model="form.contact"
        ></el-input>
      </el-form-item>

      <el-form-item
        label="负责人手机："
        prop="phone"
      >
        <el-input
          style="width:225px;"
          placeholder="请输入"
          v-model="form.phone"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="客服电话："
        class="is-required"
        prop="serviceNumber"
      >
        <el-input
          v-model="form.areaCode"
          style="width: 100px;"
        ></el-input>
        -
        <el-input
          v-model="form.serviceNumber"
          style="width: 400px;"
          placeholder="请输入准确的联系电话，便于买家联系"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱地址：" prop="email">
        <el-input
          style="width:225px;"
          placeholder="请输入"
          v-model="form.email"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="is-required"
        label="联系地址："
        prop="address"
      >
       <el-button style="min-width: 300px;" @click="showFlag = true">{{form.address==='' ? '请选择地址' : form.address}}</el-button>
      </el-form-item>

    </el-form>
    <div class="btn-wrap">
      <el-button
        type="primary"
        @click="handleSave"
      >保存</el-button>
    </div>
    <iyy-mapCode :visible.sync="showFlag" :address="form.address" :location="form" @select="handleChoose" />
  </div>
</template>

<script>
// import area from '// util/area'
// import IyyUpload from '// components/IyyUpload'
// import IyyMapCode from '@/components/IyyMapCode'
import { settingBasic, settingBasicUpdate } from '@/api/marketing/shop/set'
export default {
  components: { IyyUpload, IyyMapCode },
  data () {
    return {
      showFlag: false,
      map: null,
      searchService: null,
      markers: [],
      area,
      wxCertPem: '',
      addressDetail: '',
      address: '',
      location: {},
      rules: {
        logo: [
          { required: true, message: '请选择上传文件', validator: this._validLogo }
        ],
        name: [
          { required: true, message: '请填写', trigger: 'blur' }
        ],
        contact: [
          { required: true, message: '请填写', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请填写', trigger: 'blur' }
        ],
        serviceNumber: [
          { required: true, message: '请填写', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请填写', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请填写', trigger: 'blur' }
        ]
      },
      form: {
        logo: '',
        lat: 39.916527,
        lng: 116.397128
      }
    }
  },
  methods: {
    init () {
      settingBasic().then((res) => {
        this.form = res.data
        this.form.logo = res.data.logo
        this.addressDetail = res.data.address
      })
    },
    handleChoose (data) {
      this.form.province = data.provinces.province
      this.form.city = data.provinces.city
      this.form.district = data.provinces.district
      this.form.provinceCode = data.provinces.provinceCode
      this.form.cityCode = data.provinces.cityCode
      this.form.districtCode = data.provinces.districtCode
      this.form.address = data.address
      this.form.lat = data.lat
      this.form.lng = data.lng
    },
    handleSave () {
      if (!this.form.lat || !this.form.lng) {
        this.$message({
          type: 'info',
          message: '请选择正确的详细地址'
        })
        return
      }
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        settingBasicUpdate(this.form).then((res) => {
          if (res.code === 0) {
            this.$message({ message: '保存成功', type: 'success' })
          }
        })
      })
    },
    _validLogo (rule, value, callback) {
      let flag = this.form.logo === ''
      if (flag) {
        callback(new Error('请选择上传文件'))
      }
      callback()
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style lang="scss" scoped>
.avatar {
  width: 70px;
  height: 70px;
  img {
    width: 100%;
    height: 100%;
  }
}
// .el-form-item__content{
//   min-width:800px;
// }
</style>
